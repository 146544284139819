/* 
Colors:
#F67280
#C06C84
#6C5B7B
#355C7D
*/

/* Global */

input,
textarea,
button,
select,
div,
a {
    -webkit-tap-highlight-color: transparent;
}

hr {
    height: 0px;
    width: 80%;
    border: 1px solid var(--color-text);
    margin: 0 0 0 30px;
    transition: border 1s ease;
}

/* Reuse */
.section-header {
    display: flex;
    align-items: center;
    justify-content: center;
}

.section-title {
    text-align: center;
    font-size: calc(16px + 2vw);
}

.section-subtitle {
    font-weight: 500;
    font-size: calc(12px + 1vw);
    margin: 60px 0 30px;
}

.fa-icon {
    color: var(--color-fa-icon);
}

.fa-icon-highlight {
    color: var(--color-fa-icon-highlight);
}

.hide {
    display: none;
}

@media (max-width: 768px) {
    .section-subtitle {
        font-size: calc(9px + 1vw);
    }
}

/* Fade in animation */
.fade-in {
    opacity: 0;
    transform: translateY(100px);
    transition: opacity 2s ease, transform 1s ease;
}

.fade-in-active {
    opacity: 1;
    transform: translateY(0);
}

/* Color theme */
.dark-mode {
    --color-bg: #000000;
    --color-text: #f0f0f0;

    --color-card: rgb(40, 40, 40);
    --color-card-fg: rgb(61, 61, 61);
    --colour-card-fg-secondary: rgb(86, 86, 86);

    --color-project-skill-tag-outline: rgb(35, 35, 35);
    --color-button-hover: rgb(61, 61, 61);
    
    --color-fa-icon: rgb(48, 114, 228);
    --color-fa-icon-highlight: rgb(215, 119, 45);

    --color-grad-start: #28416f;
    --color-grad-end: #1b3563;

    --color-modal: rgb(40, 40, 40);
    --color-modal-card: rgb(61, 61, 61);

    --color-gh-red: #ff7b72;
    --color-gh-blue: #a5d6ff;
    --color-gh-purple: #d2a8ff;
    --color-gh-orange: #ffa657;
    --color-gh-darkblue: #79c0ff;
    --color-gh-default: var(--color-text);
    --color-gh-nav: rgb(26, 26, 26);

    color: var(--color-text);
    background-color: var(--color-bg);
    transition: color 1s ease, background-color 1s ease;
}

.light-mode {
    --color-bg: #f0f0f0;
    --color-text: black;

    --color-card: rgb(211, 211, 211);
    --color-card-fg: rgb(192, 192, 192);
    --colour-card-fg-secondary: rgb(150, 150, 150);


    --color-project-skill-tag-outline: rgb(178, 178, 178);
    --color-button-hover: rgb(187, 186, 186);

    --color-fa-icon: rgb(67, 125, 225);
    --color-fa-icon-highlight: rgb(228, 123, 42);

    --color-grad-start: #9bafd5;
    --color-grad-end: #7d9edc;
    
    --color-modal: rgb(211, 211, 211);
    --color-modal-card: rgb(186, 186, 186);
    
    --color-gh-red: #cf222e;
    --color-gh-blue: #0a3069;
    --color-gh-purple: #8250df;
    --color-gh-orange: #953800;
    --color-gh-darkblue: #0550ae;
    --color-gh-nav: rgb(190, 190, 190);
}

/* NavBar */

.navbar {
    --main-nav-width: 75vw;
    --main-nav-border: 2px;
    --main-nav-padding-x: 20px;
    --main-nav-offset: calc(var(--main-nav-border) + var(--main-nav-padding-x));
}

.main-nav {
    display: flex;
    width: var(--main-nav-width);
    position: fixed;
    top: 3vh;
    left: calc((100vw - var(--main-nav-width)) / 2 - var(--main-nav-offset));
    right: 0;
    z-index: 2;
    justify-content: space-between;
    align-items: center;
    padding: 15px var(--main-nav-padding-x);
    background-color: var(--color-card);
    border-radius: 20px;
    border: solid var(--main-nav-border) var(--color-card-fg);
    transition: background-color 1s ease, border 1s ease;
}

.nav-logo {
    font-weight: 700;
    font-size: calc(13px + 0.5vw);
}

.nav-logo .link {
    cursor: pointer;
}

.nav-options {
    display: flex;
    gap: 20px;
    align-items: center;
}

.theme-toggle-btn {
    display: flex;
    align-items: center;
    justify-content: space-between;
    color: black;
    background-color: rgb(159, 159, 159);
    border-radius: 100px;
    height: 25px;
    width: 30px;
    padding: 0 7px;
    scale: 120%;
    cursor: pointer;
    transition: scale 0.5s ease, background-color 1s ease, color 1s ease;
}

.theme-toggle-circle {
    width: 70%;
    aspect-ratio: 1/1;
    background-color: rgb(205, 205, 205);
    border-radius: 100px;
    transform: translateX(-5px);
    transition: transform 0.5s ease, background-color 1s ease;
}

.theme-toggle-circle-animation {
    transform: translateX(14px)
}

.theme-toggle-icon {
    display: flex;
    align-items: center;
    justify-content: center;
    height: 100%;
    aspect-ratio: 1/1;
    scale: 70%;
}

.nav-links {
    display: flex;
    gap: 20px;
}

.nav-links .link {
    font-size: calc(13px + 0.3vw);
    font-weight: 500;
    text-decoration: none;
    cursor: pointer;
    transition: opacity 0.5s ease;
}

.nav-links:has(:hover) .link:not(:hover) {
    opacity: 0.2;
}

.hamburger-btn {
    display: none;
    cursor: pointer;
}

.hamburger-line {
    width: 25px;
    height: 3px;
    background-color: var(--color-text);
    margin: 5px;
    border-radius: 2px;
    transition: background-color 1s ease;
}

.dropdown-nav {
    display: none;
}

@media (max-width: 768px) {
    .navbar {
        --main-nav-height: 20px;
        --main-nav-width: 70vw;
    }

    .main-nav {
        height: var(--main-nav-height);
        width: var(--main-nav-width);
        top: 3vh;
        left: calc((100vw - var(--main-nav-width)) / 2 - 20px);
    }

    .nav-links {
        display: none;
    }

    .hamburger-btn {
        display: block;
    }

    .dropdown-nav {
        max-height: calc(var(--main-nav-height) + (15px * 2));
        width: calc(var(--main-nav-width) + 20px * 2);
        overflow-y: hidden;
        position: fixed;
        top: 3vh;
        left: calc((100vw - var(--main-nav-width)) / 2 - 20px);
        z-index: 1;
        display: flex;
        flex-direction: column;
        gap: 10px;
        align-items: center;
        border-radius: 20px;
        background-color: var(--color-card);
        opacity: 0.9;
        border: solid 2px var(--color-card-fg);
        transition: max-height 1s ease, background-color 1s ease, border 1s ease;
    }

    .dropdown-nav-top-buffer {
        height: calc(var(--main-nav-height) + (15px * 2));
        flex-shrink: 0;
    }

    .dropdown-nav-bottom-buffer {
        height: 5px;
        flex-shrink: 0;
    }

    .dropdown-nav-expanded {
        max-height: 100vh;
    }

    .dropdown-nav .link {
        font-size: calc(13px + 0.3vw);
        font-weight: 500;
        text-decoration: none;
        cursor: pointer;
        transition: opacity 0.5s ease;
    }

    .dropdown-nav:has(:hover) .link:not(:hover) {
        opacity: 0.2;
    }
}

/* Buffer */
.buffer {
    height: 84px;
}

/* Intro */

.intro {
    --intro-content-left-width: 35%;
    --intro-content-right-width: 65%;
    padding: 10% 0;
    height: 500px;
    display: flex;
    align-items: center;
    justify-content: center;
    background-size: cover;
    background-position: center;
}

.intro-content {
    display: flex;
    align-items: center;
    justify-content: space-between;
    height: 90%;
    width: 75%;
}

.intro-content-left {
    height: 100%;
    width: var(--intro-content-left-width);
    display: flex;
    flex-direction: column;
    justify-content: center;
    color: #f0f0f0;
}

.intro-content-text {
    transform: translateX(-80%);
    opacity: 1;
    filter: blur(5px);
    transition: transform 1s ease, opacity 0.5s ease, filter 0.5s ease;
}

.intro-title {
    font-size: 96px;
    font-weight: 700;
    margin: 0;
}

.intro-subtitle {
    font-size: 46px;
    font-weight: 300;
    margin: 0;
}

.intro-link {
    font-weight: 300;
    border: solid 1.5px #f0f0f0;
    border-radius: 10px;
    width: fit-content;
    padding: 10px 20px;
    margin: 20px 0;
    cursor: pointer;
    transform: translateY(100px);
    opacity: 0;
    filter: blur(5px);
    transition: transform 1s ease, opacity 0.5s ease, filter 1s ease, background-color 0.5s ease;

    &:hover {
        background-color: rgb(61, 61, 61);
    }
}

.intro-content-right {
    display: flex;
    justify-content: flex-end;
    height: 100%;
    width: var(--intro-content-right-width);
    transform: translateX(15%);
    opacity: 0;
    filter: blur(5px);
    transition: transform 1s ease, opacity 0.5s ease, filter 1s ease, width 0.5s ease;
}

.intro-animation {
    transform: translateX(0);
    transform: translateY(0);
    opacity: 1;
    filter: blur(0px);
}

@media (max-width: 1525px) {
    .intro-title {
        font-size: 85px;
    }
    .intro-subtitle {
        font-size: 36px;
    }
}

@media (max-width: 1190px) {
    .intro {
        --intro-content-right-height: 62%;
        height: 800px;
    }
    .intro-content {
        flex-direction: column;
        gap: 20px;
    }
    .intro-content-left {
        height: calc(100% - var(--intro-content-right-height));
        width: 100%;
    }
    .intro-content-right {
        height: var(--intro-content-right-height);
        width: 100%;
    }
    .intro-title {
        font-size: calc(50px + 4vw);
    }
    .intro-subtitle {
        font-size: calc(18px + 2vw);
        width: 70%;
    }
    .intro-link {
        font-size: calc(12px + 0.5vw);
    }
}

@media (max-width: 768px) {
    .intro-content-right-expand-width {
        width: 120%;
    }
}

/* About */
.about {
    padding: 0 10%;
}

.about-section {
    display: flex;
    align-items: center;
    justify-content: space-between;
    gap: 30px;
    height: 30vw;
    padding: 0 3%;
}

.about-card {
    flex: 1;
    box-sizing: border-box;
    background-color: var(--color-card);
    border-radius: 10px;
    padding: 0 2%;
    opacity: 0;
    filter: blur(5px);
    transform: translateX(15%);
    transition: background-color 1s ease, opacity 1s ease, transform 1s ease, filter 1s ease;
}

.about-card h2 {
    font-weight: 400;
    font-size: calc(10px + 0.7vw);
    line-height: calc(10px + 2vw);
    text-align: justify;
}

.about-img {
    flex: 1;
    height: 100%;
    max-width: calc(50% - 15px);
    object-fit: cover;
    border-radius: 10px;
    opacity: 0;
    filter: blur(5px);
    transform: translateX(-100%);
    transition: opacity 1s ease, transform 1s ease, filter 1s ease;
}

@media (max-width: 1190px) {    
    .about-section {
        flex-direction: column;
        height: auto;
    }
    .about-card {
        padding: 0 4%;
    }
    .about-img {
        max-width: none;
        width: min(550px, 100%);
    }
}

/* Skills */
.skills {
    padding: 0 10%;
}

/* .skill-categories */

.skill-category h2 {
    margin: calc(30px + 1.5vw) 5%;
}

.skill-cards {
    display: flex;
    justify-content: space-around;
    padding: 0 5%;
}

.skill-card {
    --length: calc(20px + 15vw);
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    border-radius: 10px;
    width: var(--length);
    height: var(--length);
    background-color: var(--color-card);
    transition: background-color 1s ease;

    &:nth-child(2) {
        transition-delay: 200ms;
    }

    &:nth-child(3) {
        transition-delay: 400ms;
    }
}

.skill-card-img {
    --length: calc(10vw);
    height: var(--length);
    width: var(--length);
    border-radius: 10px;
}

.skill-name {
    font-size: calc(5px + 1vw);
    margin: 7px 0 0;
}

@media (max-width: 768px) {
    .skill-card {
        --length: calc(20px + 15vw);
    }

    .skill-card-img {
        --length: calc(10vw)
    }

    .skill-name {
        font-size: calc(4px + 1vw);
    }
}

/* Slide in animation */

.slide-in {
    opacity: 0;
    filter: blur(5px);
    transform: translateX(-100%);
    /* overrides .skill-card because transitions are on same level */
    transition: opacity 1s, transform 1s, scale 0.5s, filter 1s, background-color 1s ease;
}

.slide-in-active {
    opacity: 1;
    filter: blur(0);
    transform: translateX(0);
}

/* Focus on animation */
.skill-card:hover {
    scale: 110%;
}

.skill-cards:has(:hover) .skill-card:not(:hover) {
    opacity: 0.5;
    scale: 90%;
    filter: blur(3px);
}

/* Experience */
.experiences {
    padding: 0 10%;
}

.experience-cards {
    --card-length: calc(40px + 15vw);
    display: grid;
    grid-template-rows: 1fr;
    grid-template-columns: 1fr 1fr;
    align-items: baseline;
    justify-items: center;
    gap: 50px;
}

.experience-card {
    width: calc(var(--card-length) * 2);
    display: flex;
    flex-direction: column;
    gap: 16px;
    background-color: var(--color-card);
    border-radius: 10px;
    transition: background-color 1s ease, scale 0.5s ease, opacity 2s ease, transform 1s ease;
    padding: 4%;
    box-sizing: border-box;

    &:hover {
        scale: 105%;
    }
}

.experience-thumbnail {
    width: 100%;
    border-radius: 10px;
}

.experience-title {
    font-size: calc(12px + 0.7vw);
    margin: 0;
}

.experience-company {
    font-size: calc(9px + 0.7vw);
    font-weight: 550;
    margin: 0;
}

.experience-descriptions {
    --font-size: calc(7px + 0.7vw);
    font-size: var(--font-size);
    font-weight: 400;
    margin: 0;
    text-align: justify;
    overflow: hidden;
    display: flex;
    flex-direction: column;
    gap: 8px;
    position: relative;
    max-height: calc(var(--font-size) * 3.7);
    cursor: pointer;
    transition: max-height 1s ease;

    &::before {
        content: '';
        position: absolute;
        bottom: 0;
        left: 0;
        right: 0;
        height: 25px;
        opacity: 0; /* Default to hidden */
        transition: opacity 1s ease;
        background-image: linear-gradient(to bottom, transparent, rgb(211, 211, 211));
    }
    
    &::after {
        content: '';
        position: absolute;
        bottom: 0;
        left: 0;
        right: 0;
        height: 25px;
        opacity: 0;
        transition: opacity 1s ease;
        background-image: linear-gradient(to bottom, transparent, rgb(40, 40, 40));
    }

    .light-mode &::before {
        opacity: 1;
    }

    .dark-mode:not(.light-mode) &::after {
        opacity: 1;
    }
}

.experience-descriptions.experience-descriptions-clicked {
    max-height: 100vh;

    &::before {
        opacity: 0;
    }

    .dark-mode:not(.light-mode) &::after {
        opacity: 0;
    }
}

.experience-description {
    margin: 0;
}


.experience-buttons {
    display: flex;
    justify-content: right;
    gap: 8px;
    height: calc(30px + 1vw);
}

.experience-button {
    font-size: calc(7px + 1vw);
    height: 100%;
    aspect-ratio: 1/1;
    border: solid 2px var(--color-button-hover);
    border-radius: 7px;
    display: flex;
    align-items: center;
    justify-content: center;
    cursor: pointer;
    transition: background-color 0.5s ease, border 1s ease;

    &:hover {
        background-color: var(--color-button-hover);
    }
}

a.experience-button {
    text-decoration: none;
}

.experience-link {
    text-decoration: none;
    transition: color 1s ease;
}

.experience-open-modal {
    cursor: pointer;
    transition: color 1s ease;
}

@media (max-width: 1065px) {
    .experience-cards {
        grid-template-columns: 1fr;
        --card-length: calc(80px + 15vw);
    }
}

.experience-skill-tags {
    --skill-tag-height: clamp(30px, 6vw, 60px);
    display: flex;
    flex-wrap: nowrap;
    overflow-x: auto;
    align-items: center;
    position: relative;
    gap: 8px;
    height: var(--skill-tag-height);
    width: 100%;

    &::-webkit-scrollbar {
        display: none;
    }
}

.experience-skill-tag {
    border-radius: 100px;
    padding: 2%;
    color: var(--color-text);
    background-color: var(--color-grad-start);
    transition: opacity 0.5s ease, scale 0.5s ease, filter 1s ease, color 1s ease, background-color 1s ease;
    white-space: nowrap;
}

.experience-logo {
    overflow: hidden;
    flex-shrink: 0;
    height: var(--skill-tag-height);
    max-width: calc(var(--skill-tag-height) * 0.7);
    display: flex;
    align-items: center;
    justify-content: left;
    background-color: var(--color-card-fg);
    border-radius: 10px;
    transition: background-color 1s ease, max-width 2s ease;
    gap: calc(var(--skill-tag-height) * 0.15 + 1.1px);
    padding: 0 calc(var(--skill-tag-height) * 0.15);
    &:hover {
        max-width: 1000px;
    }
}

.experience-skill-logo {
    height: 70%;
}

.experience-skill {
    font-size: calc(7px + 0.5vw);
    font-weight: 400;
    margin: 0;
}

/* Projects */

.projects {
    padding: 0 10%;
}

.project-cards {
    --card-length: calc(40px + 15vw);
    display: grid;
    grid-template-rows: 1fr;
    grid-template-columns: 1fr 1fr;
    align-items: center;
    justify-items: center;
    gap: 50px;
}

.project-card {
    display: flex;
    align-items: center;
    justify-content: center;
    background-color: transparent;
    border-radius: 10px;
    height: var(--card-length);
    width: calc(var(--card-length) * 2);
    perspective: 1000px;
}

.project-card-inner {
    position: relative;
    width: 100%;
    height: 100%;
    transition: transform 0.8s, scale 0.5s ease;
    transform-style: preserve-3d;
    /* fixes left side of card unclickable */
    pointer-events: none;

    &:hover {
        scale: 105%;
    }
}

.project-card-front {
    display: flex;
    align-items: center;
    justify-content: space-evenly;
    border-radius: 10px;
    background-color: var(--color-card);
    transition: background-color 1s ease;
}

.project-img-box {
    height: 70%;
    aspect-ratio: 1/1;
    display: flex;
    justify-content: center;
    align-items: center;
    border-radius: 10px;
    background-color: var(--color-card-fg);
    transition: background-color 1s ease;
}

.project-img {
    height: 80%;
}

.project-title-box {
    height: 70%;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    width: 45%;
}

.project-title {
    width: 100%;
    height: 70%;
    text-align: right;
    display: flex;
    align-items: center;
    justify-content: right;
    font-size: calc(5px + 1.5vw);
    margin: 0;
}

.project-flip-icon-box {
    width: 100%;
    height: 30%;
    text-align: right;
    display: flex;
    align-items: flex-end;
    justify-content: flex-end;
}

.project-flip-icon {
    border: solid 2px var(--color-button-hover);
    border-radius: 7px;
    font-size: calc(2px + 1.5vw);
    padding: calc(2px + 0.5vw);
    cursor: pointer;
    height: 50%;
    aspect-ratio: 1/1;
    margin-left: 10px;
    display: flex;
    align-items: center;
    justify-content: center;
    transition: background-color 0.5s ease, border 1s ease;

    &:hover {
        background-color: var(--color-button-hover);
    }
}

.project-card-back {
    transform: rotateY(180deg);
}

.project-card-back-inner {
    height: 100%;
    width: 100%;
    display: flex;
    justify-content: center;
    /* align-items: center; */
    flex-direction: column;
    border-radius: 10px;
    background-color: var(--color-card);
    transition: background-color 1s ease;
    gap: 8px;
}

.project-subtitle {
    font-size: calc(3px + 1vw);
    text-align: justify;
    margin: 0 8%;
    padding: 4%;
    border: solid 2px var(--color-button-hover);
    border-radius: 10px;
    transition: background-color 0.5s ease, border 1s ease;
    &:hover {
        background-color: var(--color-button-hover);
    }
}

.project-buttons {
    display: flex;
    justify-content: right;
    font-size: calc(10px + 1.2vw);
    height: 20%;
    margin: 0 8%;
    gap: 6px;
}

.project-link {
    color: var(--color-text);
    transition: color 1s ease;
    text-decoration: none;
}

.project-buttons i {
    transition: background-color 0.5s ease, border 1s ease;
}

.project-modal, .project-link, .project-flip-icon-back-box {
    border: solid 2px var(--color-button-hover);
    border-radius: 7px;
    display: flex;
    align-items: center;
    justify-content: center;
    height: 100%;
    scale: 90%;
    aspect-ratio: 1/1;
    cursor: pointer;
    transition: background-color 0.5s ease, border 1s ease;
    
    &:hover {
        background-color: var(--color-button-hover);
    }
}

/* Flip animation */
.project-flip-card-animation {
    transform: rotateY(180deg);
}

.project-card-front,
.project-card-back {
    position: absolute;
    width: 100%;
    height: 100%;
    -webkit-backface-visibility: hidden;
    /* Safari */
    backface-visibility: hidden;
    /* fixes left side of card unclickable */
    pointer-events: auto;
}

@media (max-width: 1065px) {
    .project-cards {
        --card-length: calc(80px + 15vw);
        grid-template-columns: 1fr;
    }

    .project-card {
        scale: 100%;
    }

    .project-img {
        scale: 90%;
    }

    .project-title {
        font-size: calc(6px + 1.5vw);
    }

    .project-subtitle {
        font-size: calc(5px + 0.8vw);
    }

    .project-flip-icon {
        font-size: calc(4px + 1.5vw);
    }
}

/* Contact */
.contact {
    padding: 0 10% 5vw;
}

.contact-links {
    display: flex;
    justify-content: space-around;
    align-items: center;
    padding: 4% 10%;
}

.contact-link-box {
    --contact-link-box-height: calc(50px + 2.5vw);
    display: flex;
    justify-content: center;
    align-items: center;
    border-radius: 100px;
    width: var(--contact-link-box-height);
    height: var(--contact-link-box-height);
    background-color: var(--color-card);
    transition: opacity 0.5s ease, scale 0.5s ease, background-color 1s ease;
    scale: 90%;
}

.contact-fa-icons {
    font-size: var(--contact-link-box-height);
    scale: 60%;
}

.copyright {
    display: flex;
    justify-content: center;
    margin: 20px 0 0;
    font-size: calc(8px + 1vw);
}

.contact-link-box:hover {
    scale: 110%;
}

.contact-links:has(:hover) .contact-link-box:not(:hover) {
    scale: 90%;
    opacity: 0.4;
    filter: blur(3px);
}

@media (max-width: 768px) {
    .contact-links {
        padding: 4% calc(30px + 4vw);
    }

    .contact-link-box {
        --contact-link-box-height: calc(30px + 2.5vw);
        scale: 90%;
    }

    .fa-icons {
        scale: 50%;
    }
}

/* Backdrop */
.backdrop {
    height: 100%;
    width: 100%;
    position: fixed;
    top: 0;
    left: 0;
    z-index: 3;
    display: flex;
    align-items: center;
    justify-content: center;
    background: #000000aa;
}

/* Modal */
.modal {
    width: clamp(50%, 700px, 90%);
    height: 85vh;
    border-radius: 12px;
    background-color: var(--color-modal);
    overflow-y: auto;
}

.modal-nav {
    position: sticky;
    top: 0;
    left: 0;
    right: 0;
    display: flex;
    justify-content: space-between;
    padding: 3%;
    /* padding: calc(2.5px + 1.5vh) 4%; */
    background-color: var(--color-modal-card);
    z-index: 1;
}

.modal-title {
    margin: 0;
    font-size: calc(15px + 0.5vw);
    display: flex;
    align-items: center;
}

.modal-buttons {
    display: flex;
    flex-direction: row;
    gap: 8px;
    height: 40px;
}

.modal-button {
    font-size: 20px;
    cursor: pointer;
    border: 2px solid var(--colour-card-fg-secondary);
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 10px;
    height: 100%;
    aspect-ratio: 1;
    text-decoration: none;
    transition: background-color 0.5s ease;
    
    &:hover {
        background-color: var(--colour-card-fg-secondary);
    }
}

.modal-content {
    padding: 1% 4%;
}

/* ProjectsModalContent */
.modal-heading {
    font-size: calc(14px + 0.5vw);
}

.modal-subheading {
    font-size: calc(10px + 0.5vw);
}

.modal-project-skill-tags {
    display: flex;
    flex-wrap: nowrap;
    overflow-x: auto;
    align-items: center;
    position: relative;
    justify-content: flex-start;
    gap: 8px;
}

.modal-project-skill-tag {
    border: 2px solid var(--color-project-skill-tag-outline);
    border-radius: 100px;
    padding: 10px 20px;
    color: var(--color-text);
    background: linear-gradient(112.1deg, var(--color-grad-start) 11.4%, var(--color-grad-end) 70.2%);
    transition: opacity 0.5s ease, scale 0.5s ease, filter 1s ease;
    white-space: nowrap;

    /* &:hover {
        user-select: none;
        scale: 110%;
    } */
}

/* .modal-project-skill-tags:has(:hover) .modal-project-skill-tag:not(:hover) {
    opacity: 0.5;
    scale: 90%;
    filter: blur(2px);
} */

.modal-project-skill {
    margin: 0;
}

.modal-comments-card {
    background-color: var(--color-modal-card);
    border-radius: 10px;
    padding: 5px clamp(3%, 20px, 5%);
    margin: 20px 0;
    transition: background-color 1s ease;
}

.modal-features-card {
    background-color: var(--color-modal-card);
    border-radius: 10px;
    padding: 5px clamp(3%, 20px, 5%) 15px;
    margin: 20px 0;
    transition: background-color 1s ease;
}

.modal-features-medias {
    display: flex;
    flex-direction: column;
    align-items: center;
}

.modal-features-media {
    margin: 10px 0;
    border-radius: 10px;
}

@media(max-width: 768px) {
    .modal-nav {
        padding: 4%;
    }
}

/* IDE */

.ide {
    --ide-border-radius: 10px;
    height: 100%;
    width: 90%;
    background-color: var(--color-card);
    border-radius: var(--ide-border-radius);
    transition: background-color 1s ease;
    overflow: auto;
}

.ide-tabs {
    position: sticky;
    top: 0;
    left: 0;
    right: 0;
    background-color: var(--color-gh-nav);
    border-radius: var(--ide-border-radius) var(--ide-border-radius) 0 0;
    padding: 7px 7px 0;
    display: flex;
    justify-content: space-between;
    transition: background-color 1s ease;
}

.ide-files {
    display: flex;
    flex-direction: row;
    gap: 7px;
}

.ide-tab {
    width: fit-content;
    padding: 7px 12px;
    border-radius: var(--ide-border-radius) var(--ide-border-radius) 0 0;
    background-color: var(--color-card);
    transition: background-color 1s ease, opacity 1s ease;
    cursor: pointer;
    background-color: var(--color-gh-nav);
    opacity: 0.5;
}
.ide-tab-selected {
    background-color: var(--color-card);
    opacity: 1;
}

.ide-tab-file {
    font-family: -apple-system,BlinkMacSystemFont,"Segoe UI","Noto Sans",Helvetica,Arial,sans-serif,"Apple Color Emoji","Segoe UI Emoji";
    font-weight: 350;
    margin-left: 7px;
}

.ide-expand-icon-box {
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 5px 8px;
    margin-bottom: 5px;
    border: solid 1.5px var(--color-card-fg);
    border-radius: 5px;
    cursor: pointer;
    transition: background-color 0.5s ease, border 1s ease;
    display: none;
    &:hover {
        background-color: var(--color-card-fg);
    }
}

.ide-body {
    padding: 10px;
    width: fit-content;
}

.ide-line {
    display: flex;
    margin: 5px;
    font-size: 16px;
    width: fit-content;
}

.ide-line-number {
    width: 20px;
    margin: 0 7px 0 0;
}

.ide-line-text-box {
    display: flex;
}

.ide-line-text {
    margin: 0;
}

@media(max-width: 1190px) {
    .ide {
        width: 100%;
    }
}

@media (max-width: 768px) {
    .ide-expand-icon-box {
        display: block;
    }
}

/* Lightbulb */
.lightbulb-button {
    --lightbulb-button-height: 0px; /* Set in Lightbulb.js */
    --lightbulb-width: 0px; /* Set in Lightbulb.js */
    --spacing: calc((var(--lightbulb-button-height) - var(--lightbulb-width)) / 2);
    height: 100%;
    max-width: calc(var(--lightbulb-button-height) - var(--spacing) * 2);
    overflow: hidden;
    flex-shrink: 0;
    border: solid 2px var(--color-button-hover);
    border-radius: 7px;
    display: flex;
    align-items: center;
    justify-content: left;
    cursor: pointer;
    transition: background-color 0.5s ease, border 1s ease, max-width 4s ease;
    padding: 0 var(--spacing);
    gap: calc(var(--spacing) + 2px);

    &:hover {
        background-color: var(--color-button-hover);
    }
}

.lightbulb-button-expand {
    max-width: 1000px;
}

.lightbulb {
    font-size: calc(7px + 1vw);
}

.lightbulb-text {
    font-size: calc(7px + 0.5vw);
    font-weight: 400;
    text-wrap: nowrap;
}