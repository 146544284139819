html {
    font-family: 'Montserrat', sans-serif;
}

body {
    margin: 0;
}

::-webkit-scrollbar {
    display: none;
}